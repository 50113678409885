<template>
  <div>
    <PageIsClosed v-if="getPageIsClosed" />
    <SignIn v-else></SignIn>
  </div>
</template>

<script>
import SignIn from "../components/SignIn";
import PageIsClosed from "../components/PageIsClosed/index.vue";

export default {
  components: { SignIn, PageIsClosed },
  computed: {
    getPageIsClosed() {
      return this.$store.state.User.pageIsClosed;
    },
  },
  mounted() {
    console.log(this.$store.state.User);
  },
};
</script>
