<template>
  <div class="container-fluid">
    <div class="row login-area align-items-center">
      <div class="col-12 col-lg-6 bg-white h-100 d-flex align-items-center">
        <section class="form_popup">
          <div class="login_form" id="login_form">
            <div class="hd-lg">
              <img :src="getSettings.logo" width="140" alt="logo" />
              <span
                v-html="$t('signInAccount', { company: getSettings.title })"
              ></span>
            </div>
            <!--hd-lg end-->
            <div class="user-account-pr">
              <div
                class="alert text-center"
                :class="`alert-${getAlertBox.variant}`"
                v-if="getAlertBox.status && getAlertBox.message !== '_'"
              >
                {{ getAlertBox.message }}
              </div>
              <form @submit="SEND_TO_LOGIN">
                <div class="input-sec" v-if="!fromQueryEmail">
                  <input
                    required
                    type="email"
                    v-model="form.email"
                    name="username"
                    :placeholder="$t('email')"
                  />
                </div>
                <div v-else>
                  <div
                    class="email-card bg-light d-flex flex-row align-items-center"
                  >
                    <h3 class="col-2"><i class="icon-user"></i></h3>
                    <div class="col-10 position-relative">
                      <div>{{ form.email }}</div>
                      <div class="text-right">
                        <small @click="fromQueryEmail = false"
                          >başka bir e-mail adresi ile gir</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="my-3">Hesabının şifresini girin...</div>
                </div>
                <div class="input-sec">
                  <input
                    required
                    minlength="3"
                    v-model="form.password"
                    type="password"
                    name="password"
                    :placeholder="$t('password')"
                  />
                </div>
                <div class="text-right mb-2">
                  <a
                    :href="
                      'https://' + getSettings.website + '/forget-password'
                    "
                    >{{ $t("forgetPassword") }}</a
                  >
                </div>
                <div class="input-sec mb-0">
                  <button :disabled="getLoginIsLoading" type="submit">
                    <Loading v-if="getLoginIsLoading"></Loading>
                    <span v-else>{{ $t("signIn") }}</span>
                  </button>
                </div>
                <!--input-sec end-->
              </form>
            </div>
            <!--user-account end--->
          </div>
          <!--login end--->
        </section>
        <!--form_popup end-->
      </div>
      <div class="col-12 col-lg-6 d-none d-lg-block">
        <img src="/side1.webp" width="100%" alt="heocademy" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      fromQueryEmail: false,
    };
  },
  computed: {
    getAlertBox() {
      return this.$store.state.User.alertBox;
    },
    getLoginIsLoading() {
      return this.$store.state.User.loginIsLoading;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    async CHECK_QUERY() {
      const q = this.$route.query;
      if (q.email && q.password) {
        this.form.email = q.email;
        this.form.password = q.password;
        this.$store.dispatch("SEND_TO_LOGIN", this.form);
      } else if (q.email) {
        this.form.email = q.email;
        this.fromQueryEmail = true;
      }
    },
    async SEND_TO_LOGIN(e) {
      e.preventDefault();
      this.$store.dispatch("SEND_TO_LOGIN", this.form);
    },
  },
  created() {
    this.CHECK_QUERY();
  },
};
</script>

<style></style>
